import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Text, Button, Box, Icon, Link, Section, Em } from "@quarkly/widgets";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
const defaultProps = {
	"padding": "80px 0 0 0",
	"quarkly-title": "Contacts-6"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-around",
			"lg-flex-direction": "column",
			"margin": "0px 0px 50px 0px",
			"lg-align-items": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/8748255_11.jpg?v=2023-05-27T11:28:27.496Z",
			"display": "block",
			"width": "50%",
			"object-fit": "cover",
			"height": "100%",
			"lg-width": "100%",
			"sm-height": "280px",
			"margin": "0px 30px 0px 30px",
			"lg-margin": "0px 30px 30px 30px",
			"lg-height": "auto"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"lg-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"lg-margin": "0px 0px 50px 0px",
			"align-items": "flex-start",
			"padding": "0px 50px 0px 50px",
			"sm-padding": "0px 15px 0px 15px",
			"sm-margin": "0px 0px 0 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 25px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sansHelvetica",
			"padding": "0px 10px 0px 0px",
			"border-color": "#c9d0d7",
			"sm-border-width": 0,
			"color": "--primary",
			"text-transform": "uppercase",
			"children": "ВАША ПОДОРОЖ ДО НОВОГО БУДИНКУ ПОЧИНАЄТЬСЯ ТУТ"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 38px/1.2 --fontFamily-sansHelvetica",
			"sm-font": "normal 600 28px/1.2 --fontFamily-sansHelvetica",
			"border-color": "--color-secondary",
			"color": "--secondary",
			"children": "Телефонуйте 0672475151"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--lead",
			"color": "#8b9197",
			"children": <>
				Дізнайтеся більше про Starlight Real Estate!{" "}
				<br />
				Зв'яжіться з нами сьогодні, щоб дізнатися, як ми можемо висвітлити ваш шлях до ідеального будинку!
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"display": "none"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "0px 15px 0px 0px",
			"padding": "14px 28px 14px 28px",
			"border-radius": "50px",
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"sm-margin": "0px 15px 15px 0px",
			"text-transform": "uppercase",
			"type": "link",
			"href": "/contacts",
			"text-decoration-line": "initial",
			"background": "--color-secondary",
			"children": "Usługi"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"margin": "0px 15px 0px 0px",
			"padding": "14px 28px 14px 28px",
			"border-radius": "50px",
			"font": "--base",
			"sm-margin": "0px 15px 15px 0px",
			"text-transform": "uppercase",
			"type": "link",
			"text-decoration-line": "initial",
			"href": "/contacts",
			"background": "--color-secondary",
			"children": "Łączność"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0 0 0",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"padding": "50px 50px 50px 50px",
			"align-items": "center",
			"lg-grid-gap": "36px 34px",
			"md-grid-template-columns": "1fr",
			"sm-padding": "35px 15px 35px 15px",
			"background": "--color-primary"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%",
			"color": "--secondary"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+9877654321223",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"children": <>
				Serzha Lyfaria St, 5,{" "}
			</>
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": <>
				{" "}Kyiv, 02222
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%",
			"color": "--secondary"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"href": "0672475151",
			"children": "0672475151"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": "Зателефонувати"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdMailOutline,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%",
			"border-color": "--color-secondary",
			"color": "--secondary"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"href": "info@forestihaven.com",
			"children": "info@forestihaven.com"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": "Написати"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="column" min-width="100%" width="100%" />
		<Box {...override("box")}>
			<Image {...override("image")} />
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
				<Box {...override("box2")}>
					<Button {...override("button")}>
						Başlamak
					</Button>
					<Button {...override("button1")}>
						Kişiler
					</Button>
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Icon {...override("icon")} />
				<Box {...override("box5")}>
					<Link {...override("link")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Icon {...override("icon1")} />
				<Box {...override("box7")}>
					<Link {...override("link1")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box8")}>
				<Icon {...override("icon2")} />
				<Box {...override("box9")}>
					<Link {...override("link2")} />
					<Text {...override("text5")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;